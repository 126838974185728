<template>
  <CommonPage>
    <div class="indexContent">
      <div class="banner flex aic" :style="{ background: `url(${imgUrl})` }" @click="flash">
        <div class="son">
          <div class="top">
            Daily Quote
          </div>
          <div class="middle">
            <div class="mt">
              {{ quoteAla }}
            </div>
          </div>
          <div class="bottom">
            <div>{{ quoteEng }}</div>
            <div>{{ posi }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <Ad :ads="adsensConfig.ad1" class="span2" />
        <div class="qg">
          Quran&Journey Org
        </div>

        <div class="chapters">
          <div v-for="(item, index) in chapters" :key="item.transliteratedName"
            class="item flex aic" @click="
							$router.push({
								name: 'detail',
								params: {
									chapter: item.transliteratedName,
								},
							})
						">
            <div class="left">
              {{ index + 1 }}
            </div>
            <div class="right flex f1 sb aic">
              <div class="left">
                <div class="top">
                  {{ item.transliteratedName }}
                </div>
                <div class="bottom">
                  {{ item.translatedName }}
                </div>
              </div>
              <div class="right">
                <div class="top">
                  {{ String(index + 1).padStart(3, '0') }}
                </div>
                <div class="bottom">
                  {{ item.versesCount }} Ayahs
                </div>
              </div>
            </div>
          </div>
          <Ad v-if="deviceType === 'desktop'" ref="ad4" :ads="adsensConfig.ad4"
            class="span2 ad4pc" />
          <Ad v-if="deviceType === 'mobile'" ref="ad4" :ads="adsensConfig.ad4" class="span2 ad4m" />
        </div>
      </div>

      <Ad ref="ad2" :ads="adsensConfig.ad2" class="span2 ad2" />
      <Ad ref="ad3" :ads="adsensConfig.ad3" class="span2 ad3" />
      <!-- (index === 8 && deviceType === 'mobile') || (index === 9 && deviceType === 'desktop') -->
    </div>
  </CommonPage>
</template>
<script>
const di = {
  mobile: 'm',
  desktop: 'p',
}
const dic = [
  {
    quoteAla: 'فَجَعَلَهُمْ كَعَصْفٍۢ مَّأْكُولٍۭ',
    posi: '(105:5)',
    quoteEng: 'Leaving them like chewed-up leaves.',
  },
  {
    quoteAla: 'أَلَمْ يَجِدْكَ يَتِيمًۭا فَـَٔاوَىٰ',
    posi: '(93:6)',
    quoteEng: 'Did He not find you orphaned, and sheltered you?',
  },
  {
    quoteAla: 'فَوَيْلٌۭ لِّلْمُصَلِّينَ',
    posi: '(107:4)',
    quoteEng: 'So woe to those who pray.',
  },
  {
    quoteAla: 'ذُو ٱلْعَرْشِ ٱلْمَجِيدُ',
    posi: '(85:15)',
    quoteEng: 'Possessor of the Glorious Throne.',
  },
  {
    quoteAla: 'لِّلطَّٰغِينَ مَـَٔابًۭا',
    posi: '(78:22)',
    quoteEng: 'For the oppressors, a destination.',
  },
  {
    quoteAla: 'إِنَّ بَطْشَ رَبِّكَ لَشَدِيدٌ',
    posi: '(85:12)',
    quoteEng: 'The onslaught of your Lord is severe.',
  },
  {
    quoteAla: 'فَوَيْلٌۭ لِّلْمُصَلِّينَ',
    posi: '(107:4)',
    quoteEng: 'So woe to those who pray.',
  },
  {
    quoteAla: 'وَيَمْنَعُونَ ٱلْمَاعُونَ',
    posi: '(107:7)',
    quoteEng: 'And withhold the assistance.',
  },
  {
    quoteAla: 'وَإِلَى ٱلْجِبَالِ كَيْفَ نُصِبَتْ',
    posi: '(88:19)',
    quoteEng: 'And at the mountains-how they are installed?',
  },
  {
    quoteAla: 'وَيْلٌۭ لِّكُلِّ هُمَزَةٍۢ لُّمَزَةٍ',
    posi: '(104:1)',
    quoteEng: 'Woe to every slanderer backbiter.',
  },
  {
    quoteAla: 'إِنَّ ٱلْإِنسَٰنَ لَفِى خُسْرٍ',
    posi: '(103:2)',
    quoteEng: 'The human being is in loss.',
  },
  {
    quoteAla: 'لَقَدْ رَأَىٰ مِنْ ءَايَٰتِ رَبِّهِ ٱلْكُبْرَىٰٓ',
    posi: '(53:18)',
    quoteEng: 'He saw some of the Great Signs of his Lord.',
  },
  {
    quoteAla: 'فَبِأَىِّ ءَالَآءِ رَبِّكَ تَتَمَارَىٰ',
    posi: '(53:55)',
    quoteEng: "So which of your Lord's marvels can you deny?",
  },
  {
    quoteAla: 'وَيَتَجَنَّبُهَا ٱلْأَشْقَى',
    posi: '(87:11)',
    quoteEng: 'But the wretched will avoid it.',
  },
  {
    quoteAla: 'فَمَا لَهُۥ مِن قُوَّةٍۢ وَلَا نَاصِرٍۢ',
    posi: '(86:10)',
    quoteEng: 'He will have no strength, and no supporter.',
  },
  {
    quoteAla: 'قَوَارِيرَا۟ مِن فِضَّةٍۢ قَدَّرُوهَا تَقْدِيرًۭا',
    posi: '(76:16)',
    quoteEng: 'Crystal of silver-they measured them exactly.',
  },
  {
    quoteAla: 'وَٱلْأَرْضِ ذَاتِ ٱلصَّدْعِ',
    posi: '(86:12)',
    quoteEng: 'And the earth that cracks open.',
  },
  {
    quoteAla: 'وَإِنَّهُۥ لِحُبِّ ٱلْخَيْرِ لَشَدِيدٌ',
    posi: '(100:8)',
    quoteEng: 'And he is fierce in his love of wealth.',
  },
  {
    quoteAla: 'فَأَمَّا مَنْ أَعْطَىٰ وَٱتَّقَىٰ',
    posi: '(92:5)',
    quoteEng: 'As for him who gives and is righteous.',
  },
  {
    quoteAla: 'مَا ٱلْقَارِعَةُ',
    posi: '(101:2)',
    quoteEng: 'What is the Shocker?',
  },
  {
    quoteAla: 'فَجَعَلَهُمْ كَعَصْفٍۢ مَّأْكُولٍۭ',
    posi: '(105:5)',
    quoteEng: 'Leaving them like chewed-up leaves.',
  },
  {
    quoteAla: 'أَحْيَآءًۭ وَأَمْوَٰتًۭا',
    posi: '(77:26)',
    quoteEng: 'For the living and the dead?',
  },
  {
    quoteAla: 'أَتَبْنُونَ بِكُلِّ رِيعٍ ءَايَةًۭ تَعْبَثُونَ',
    posi: '(26:128)',
    quoteEng: "Do you build a monument on every height for vanity's sake?",
  },
  {
    quoteAla: 'مَلِكِ ٱلنَّاسِ',
    posi: '(114:2)',
    quoteEng: 'The King of mankind.',
  },
  {
    quoteAla: 'فَٱلتَّٰلِيَٰتِ ذِكْرًا',
    posi: '(37:3)',
    quoteEng: 'And the reciters of the Reminder.',
  },
  {
    quoteAla: 'وَرَفَعْنَا لَكَ ذِكْرَكَ',
    posi: '(94:4)',
    quoteEng: 'And raised for you your reputation?',
  },
  {
    quoteAla: 'بَلْ هُوَ قُرْءَانٌۭ مَّجِيدٌۭ',
    posi: '(85:21)',
    quoteEng: 'In fact, it is a Glorious Quran.',
  },
  {
    quoteAla: 'تَبَّتْ يَدَآ أَبِى لَهَبٍۢ وَتَبَّ',
    posi: '(111:1)',
    quoteEng: 'Condemned are the hands of Abee Lahab, and he is condemned.',
  },
  {
    quoteAla: 'ٱلَّتِى لَمْ يُخْلَقْ مِثْلُهَا فِى ٱلْبِلَٰدِ',
    posi: '(89:8)',
    quoteEng: 'The like of which was never created in the land.',
  },
  {
    quoteAla: 'وَسُيِّرَتِ ٱلْجِبَالُ فَكَانَتْ سَرَابًا',
    posi: '(78:20)',
    quoteEng: 'And the mountains are set in motion, and become a mirage.',
  },
  {
    quoteAla: 'وَإِلَى ٱلسَّمَآءِ كَيْفَ رُفِعَتْ',
    posi: '(88:18)',
    quoteEng: 'And at the sky-how it is raised?',
  },
  {
    quoteAla: 'إِنَّ رَبَّهُم بِهِمْ يَوْمَئِذٍۢ لَّخَبِيرٌۢ',
    posi: '(100:11)',
    quoteEng: 'Their Lord, on that Day, is fully informed of them.',
  },
  {
    quoteAla: 'إِنَّآ أَعْطَيْنَٰكَ ٱلْكَوْثَرَ',
    posi: '(108:1)',
    quoteEng: 'We have given you plenty.',
  },
  {
    quoteAla: 'ٱلَّذِىٓ أَنقَضَ ظَهْرَكَ',
    posi: '(94:3)',
    quoteEng: 'Which weighed down your back?',
  },
  {
    quoteAla: 'فَلْيَنظُرِ ٱلْإِنسَٰنُ إِلَىٰ طَعَامِهِۦٓ',
    posi: '(80:24)',
    quoteEng: 'Let man consider his food.',
  },
  {
    quoteAla: 'وَإِنَّهُۥ عَلَىٰ ذَٰلِكَ لَشَهِيدٌۭ',
    posi: '(100:7)',
    quoteEng: 'And he bears witness to that.',
  },
  {
    quoteAla: 'إِنَّ هَٰذَا لَفِى ٱلصُّحُفِ ٱلْأُولَىٰ',
    posi: '(87:18)',
    quoteEng: 'This is in the former scriptures.',
  },
  {
    quoteAla: 'وَثَمُودَ ٱلَّذِينَ جَابُوا۟ ٱلصَّخْرَ بِٱلْوَادِ',
    posi: '(89:9)',
    quoteEng: 'And Thamood-those who carved the rocks in the valley.',
  },
  {
    quoteAla: 'وَٱلْيَوْمِ ٱلْمَوْعُودِ',
    posi: '(85:2)',
    quoteEng: 'And by the Promised Day.',
  },
  {
    quoteAla: 'يَقُولُ يَٰلَيْتَنِى قَدَّمْتُ لِحَيَاتِى',
    posi: '(89:24)',
    quoteEng: 'He will say, "If only I had forwarded for my life."',
  },
  {
    quoteAla: 'وَٱلنَّٰشِطَٰتِ نَشْطًۭا',
    posi: '(79:2)',
    quoteEng: 'And those who remove gently.',
  },
  {
    quoteAla: 'لَكُمْ دِينُكُمْ وَلِىَ دِينِ',
    posi: '(109:6)',
    quoteEng: 'You have your way, and I have my way."',
  },
  {
    quoteAla: 'ٱهْدِنَا ٱلصِّرَٰطَ ٱلْمُسْتَقِيمَ',
    posi: '(1:6)',
    quoteEng: 'Guide us to the straight path.',
  },
  {
    quoteAla: 'فَسَجَدَ ٱلْمَلَٰٓئِكَةُ كُلُّهُمْ أَجْمَعُونَ',
    posi: '(38:73)',
    quoteEng: 'So the angels fell prostrate, all of them.',
  },
  {
    quoteAla: 'وَمَآ أَدْرَىٰكَ مَا هِيَهْ',
    posi: '(101:10)',
    quoteEng: 'Do you know what it is?',
  },
  {
    quoteAla: 'صُحُفِ إِبْرَٰهِيمَ وَمُوسَىٰ',
    posi: '(87:19)',
    quoteEng: 'The Scriptures of Abraham and Moses.',
  },
  {
    quoteAla: 'مَا زَاغَ ٱلْبَصَرُ وَمَا طَغَىٰ',
    posi: '(53:17)',
    quoteEng: 'The sight did not waver, nor did it exceed.',
  },
  {
    quoteAla: 'فَٱلْمُورِيَٰتِ قَدْحًۭا',
    posi: '(100:2)',
    quoteEng: 'Igniting sparks.',
  },
  {
    quoteAla: 'لَيْلَةُ ٱلْقَدْرِ خَيْرٌۭ مِّنْ أَلْفِ شَهْرٍۢ',
    posi: '(97:3)',
    quoteEng: 'The Night of Decree is better than a thousand months.',
  },
  {
    quoteAla: 'كَذَّبَتْ ثَمُودُ بِطَغْوَىٰهَآ',
    posi: '(91:11)',
    quoteEng: 'Thamood denied in its pride.',
  },
]
const title = 'Quran&Journey Org - Complete Quran Chapters List'
const description = 'Explore the complete list of Quran chapters. Start your journey through the Holy Quran with our comprehensive chapter listing.'
import '@/css/index.scss'
export default {
  metaInfo: {
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'title',
        content: title,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
    ],
  },
  data () {
    return {
      imgUrl: '',
      quoteAla: '',
      posi: '',
      quoteEng: '',
      chapters: [],
    }
  },
  computed: {},
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`)
    await this.loadAdSenseScript() // 重新加载广告脚本
  },
  beforeMount () {
    this.imgUrl = require(`@/assets/${di[this.deviceType]}${Math.floor(Math.random() * 4) + 1}.webp`)
    let n = Math.floor(Math.random() * 48) + 1
    this.posi = dic[n].posi
    this.quoteAla = dic[n].quoteAla
    this.quoteEng = dic[n].quoteEng
  },
  async mounted () {
    const obj = require('../quran/chapters.json')
    console.log(obj)
    this.chapters = Object.keys(obj).map((e) => obj[e])
    this.watchForIframeInsertion()

    // await this.loadAdSenseScript() // 重新加载广告脚本
    setTimeout(() => {
      if (this.isMobile) {
        // 移动端广告
        document.querySelector('.chapters :nth-child(3)').after(this.$refs.ad2.$el)
        document.querySelector('.chapters :nth-child(7)').after(this.$refs.ad3.$el)
        // document.querySelector('.chapters :nth-child(11)').after(this.$refs.ad4.$el)
      } else {
        // PC端广告位
        document.querySelector('.chapters :nth-child(4)').after(this.$refs.ad2.$el)
        document.querySelector('.chapters :nth-child(7)').after(this.$refs.ad3.$el)
        // document.querySelector('.chapters :nth-child(12)').after(this.$refs.ad4.$el)
      }
    })
  },

  activated () {
    document.querySelector('body').style.backgroundColor = '#131518'
  },
  methods: {
    //客户端交互
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) {
        // 检查广告是否已经加载
        console.log('Adsense script already loaded.')
        this.$eventrack('adscript_loaded', 'expose')
        this.loadAdWithDelay()
        return // 如果已加载，直接返回
      }
      await this.loadScriptConditionally()
    },
    loadScriptConditionally () {
      // console.log(this.adsensConfig)
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链')
        this.$eventrack('no_adscript_config', 'expose')
        return
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose')
        console.log('脚本已存在，无需重新添加')
        return
      }

      console.log('准备插入脚本')
      const script = document.createElement('script')
      script.src = this.adsensConfig?.scriptUrl
      script.crossOrigin = 'anonymous'
      script.async = true

      const header = document.getElementsByTagName('head')[0]
      header.appendChild(script)

      this.$eventrack('adscript_add_success', 'expose')
      script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd()
      }, 500)
    },
    async displayAd () {
      await this.$nextTick()

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ad')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref) // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log('Adsense script not loaded yet, delaying ad display.')
        setTimeout(this.displayAd, 500) // 延迟再次尝试
        return
      }

      console.log(adsElements) // 检查是否包含 <ins> 标签
      adsElements.forEach((ad) => {
        console.log(ad) // 输出每个广告元素
        console.log('ready to push')
          ; (window.adsbygoogle = window.adsbygoogle || []).push({})
      })
    },
    flash () {
      if (this.deviceType === 'mobile') location.href = '/'
    },
  },
}
</script>